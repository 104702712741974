import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ReassuringQualityBG from '../images/reassuring-quality.jpg';
import { Modal } from "reactstrap";

const StyledModal = styled(Modal)(({ theme }) => css`
    
    &.modal-dialog {
        margin: 0;
        max-width: initial;
    }

    .modal-content {
        min-height: 100vh;
        overflow: hidden;
        width: 100vw;
        border: 0;
        border-radius: 0;
        background: url(${ReassuringQualityBG}) no-repeat;
        background-size: 300%;
        background-position: center top;
        color: ${theme.colors.white};
        padding: 0;
    }

    .modal-body {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 150%);
        overflow: hidden;

        &.centered {
            justify-content: center;
        }
    }

    .modal-header {
        border: 0;
    }

    .modal-title {
        display: flex;
    }
`);

export default StyledModal;