import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const Paragraph = styled.p(({ theme, weight, size, uppercase }) => css`
    font-family: ${
        weight === "thin" && 'OneNightSans-Thin' ||
        weight === "medium" && 'OneNightSans-Medium' ||
        weight === "black" && 'OneNightSans-Black'
    };
    color: ${theme.colors.white};
    font-size: ${size};
    text-transform: ${uppercase && "uppercase"};
    line-height: normal;
`);

export default Paragraph;