import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Hero from '../components/Hero';

export const Section = styled.section(({ theme, background }) => css`
    background: ${theme.colors[background]};
    font-family: 'OneNightSans-Thin';
`);

const Layout = () => (
    <React.Fragment>
        <Section
            background="blue"
            className="d-flex align-items-center
            justify-items-center flex-column">
            <div className="w-100">
                <Hero />
            </div>
        </Section>
    </React.Fragment> 
);
 
export default Layout;