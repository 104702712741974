import styled, { css } from 'styled-components';

export const BorderButton = styled.button(({ theme }) => css`
    background: transparent;
    border-radius: 50px;
    border: 0px solid ${theme.colors.white};
    color: #005baa;
    background-color: #fff;
    font-family: "OneNightSans-Medium";
    font-size: 18px;
    padding: 9px 30px;
    text-transform: uppercase;
`);

export const PrimaryButton = styled.button(({ theme }) => css`
    background: ${theme.colors.blue};
    border-radius: 50px;
    border: 0px solid ${theme.colors.white};
    color: #005baa;
    background-color: #fff;
    font-family: "OneNightSans-Medium";
    font-size: 18px;
    padding: 15px 60px;
    text-transform: uppercase;
    width: 75%;
`);

export const InvisibleButton = styled.button`
    background: none;
    border: 0;
`;