import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './pages/homepage';
import { HashRouter as Router, Link, Route, Routes } from 'react-router-dom';

const theme = {
    colors: {
        black: '#000',
        white: '#fff',
        blue: '#005CAA'
    },
    fonts: {
        thin: 'OneNightSans-Thin, Helvetica, sans-serif',
        medium: 'OneNightSans-Medium, Helvetica, sans-serif',
        black: 'OneNightSans-Black, Helvetica, sans-serif',
    },
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1400px',
        xxxl: '1600px',
        fhd: '1920px',
        qhd: '2560px',
        uhd: '3840px',
    },
    headingSizes: {
        mobile: {
            h1: '36px',
        },
        desktop: {
            h1: '50px',
        }
    }
  }

export function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="/" element={ <Layout /> } />
                    </Routes>
                </Router>
            </ThemeProvider>
        </React.Fragment>
    );
  }
