import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Header from "../components/header";
import DurexWhite from "../images/durex-logo-white.svg";
import IosLaunch from "../images/iosLaunchButton.png";
import ThinAndLightBG from "../images/thinnest-and-lightest.jpg";
import SmoothAndSoftBG from "../images/smooth-and-soft.jpg";
import ThinAndSensitiveBG from "../images/thin-and-sensitive.jpg";
import ReassuringQualityBG from "../images/reassuring-quality.jpg";
import SelectionArrow from "../images/selection-arrow.svg";
import QRCode from "../images/findyourbestfit-QR-Code.svg";
import { ModalBody } from "reactstrap";
import { InvisibleButton, PrimaryButton, BorderButton } from "./buttons";
import Paragraph from "./paragraph";
import StyledModal from "./modals";
import RegularSizeProduct from "../images/invisible-regular-optimised.png";
import LargeSizeProduct from "../images/realfeel-large-optimised.png";
import XLSizeProduct from "../images/sensithin-xl-optimised.png";
import GA4React from "ga-4-react";

const TRACKING_ID = "G-FXC43N15HK"; // YOUR_OWN_TRACKING_ID
const ga4react = new GA4React(TRACKING_ID);
ga4react.initialize().then().catch()

// Styled Components
const HeroBase = styled.div(({ index }) => css`
	min-height: calc(100vh - 100px);

	.bottom {
		min-height: 55vh;

		.sizeContainer{
			width: 300px;
			min-width: 300px;
		}

		@media screen and (min-height: 768px){
			min-height: 50vh;
		}
	}

	.top {
		min-height: 45vh;
		align-items: center;
		justify-content: center;
		padding-bottom: 20px;
		animation: ${FadeBackground} infinite 12s linear;
		
		@media screen and (min-height: 768px){
			min-height: 50vh;
		}
	}

	.top-desktop {
		background: url(${ReassuringQualityBG}) no-repeat center center;
		background-size: cover;
		min-height: 50vh;
		align-items: center;
		justify-content: center;
		padding-bottom: 20px;
		min-height: 50vh;
	}

	.middle {
		position: relative;
	}
`);

const DurexLogo = styled.img(({ theme }) => css`
	z-index: 8;
	max-width: 125px;
	min-width: 125px;
	position: absolute;
	bottom: -24px;

	@media screen and (min-width: ${theme.breakpoints.md}) {
		max-width: 250px;
		min-width: 250px;
		bottom: -40px;
	}
`);

const GirthIndicator = ({ selectedRing }) => (
	<svg
		width="100px"
		height="60px"
		viewBox="0 0 100 100"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		className="w-100"
	>
		<title>Girth Indicator</title>
		<g
			id="Girth-Indicator"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeOpacity="0.501662888"
		>
			<circle
				id="Ring-5"
				stroke="#FFFFFF"
				strokeWidth="5"
				cx="50"
				cy="50"
				r="47.5"
				strokeOpacity={selectedRing === "1" && "1"}
			></circle>
			<circle
				id="Ring-4"
				stroke="#FFFFFF"
				strokeWidth="5"
				cx="50"
				cy="50"
				r="37.5"
				strokeOpacity={selectedRing === "2" && "2"}
			></circle>
			<circle
				id="Ring-3"
				stroke="#FFFFFF"
				strokeWidth="5"
				cx="50"
				cy="50"
				r="27.5"
				strokeOpacity={selectedRing === "3" && "3"}
			></circle>
			<circle
				id="Ring-2"
				stroke="#FFFFFF"
				strokeWidth="5"
				cx="50"
				cy="50"
				r="17.5"
				strokeOpacity={selectedRing === "4" && "4"}
			></circle>
			<circle
				id="Ring-1"
				stroke="#FFFFFF"
				strokeWidth="5"
				cx="50"
				cy="50"
				r="7.5"
				strokeOpacity={selectedRing === "5" && "5"}
			></circle>
		</g>
	</svg>
);

export const Load = keyframes`
    from { width: 5%; }
    to { width: 100%; }
`;

export const FadeProduct = keyframes`
    0% {
		opacity: 0%;
	}
    100% {
		opacity: 100%;
	}
`;

export const FadeBackground = keyframes`
	0% {
		background: url(${ThinAndLightBG}) no-repeat center center;
		background-size: 100% 100%;
	}
	35% {
		background: url(${ThinAndLightBG}) no-repeat center center;
		background-size: 110% 110%;
	}
	36% {
		background: url(${SmoothAndSoftBG}) no-repeat center center;
		background-size: 110% 110%;
	}
	68% {
		background: url(${SmoothAndSoftBG}) no-repeat center center;
		background-size: 120% 120%;
	}
	69% {
		background: url(${ThinAndSensitiveBG}) no-repeat center center;
		background-size: 120% 120%;
	}
	100% {
		background: url(${ThinAndSensitiveBG}) no-repeat center center;
		background-size: 130% 130%;
	}
`;

const LoadingBase = styled.svg`
	width: 100%;
	#filledBar {
		animation: ${Load} 4s 1 linear;
	}
`;

const LoadingBar = () => (
	<LoadingBase
		width="491px"
		height="41px"
		viewBox="0 0 491 41"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<title>Loading</title>
		<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<rect id="barBG" fillOpacity="0.5" fill="#FFFFFF" x="0" y="0" width="491" height="41" rx="20.5"></rect>
			<rect id="filledBar" fillOpacity="0.9" fill="#FFFFFF" x="0" y="0" width="41" height="41" rx="20.5"></rect>
		</g>
	</LoadingBase>
);

export const AnimateArrow = keyframes`
	0% {
		transform: translate(-100px, 0px);
	}
	30% {
		transform: translate(-100px, 0px);
	}
	35% {
		transform: translate(0px, 0px);
	}
	65% {
		transform: translate(0px, 0px);
	}
	70% {
		transform: translate(100px, 0px);
	}
	94% {
		transform: translate(100px, 0px);
	}
	99% {
		transform: translate(-100px, 0px);
	}
	100% {
		transform: translate(-100px, 0px);
	}
`;

const ArrowIndicator = styled.img`
	max-width: 20px;
	animation: ${AnimateArrow} 12s linear infinite;
`;

const Product = styled.img`
	max-width: 165px;
	animation: ${FadeProduct} 0.3s linear;

	@media screen and (min-height: 770px) {
		max-width: 50%;
	}

	@media screen and (min-height: 950px) and (min-width: 768px) {
		max-width: 100%;
	}
`;

const DesktopQRCode = styled.img`
	max-width: 240px;
	border-radius: 30px;
`;

const IosLaunchImage = styled.img`
	max-width: 120px;
	display:none;
`;


const MobileSpacer = styled.hr`
	background: transparent;
	height: 0px;
	margin: 0;

	@media screen and (min-height: 960px) {
		width: 100%;
		margin: 10px;
	}
`;

const Hero = () => {
	const [modal, setModal] = useState(false);
	const [modalMode, setModalMode] = useState("cookies");
	const [index, setIndex] = useState(0);

	const openModal = () => {
		setModal(!modal);
	};

	// Opening AR projects
	//console.log(navigator.userAgent);
	var isAndroid = /android/i.test(navigator.userAgent);
	var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	var androidIntentPrefix = 'intent://arvr.google.com/scene-viewer/1.1?mode=ar_preferred&resizable=true&file=';
	var androidIntentSuffix = '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;';

	var androidModelLink = 'https://findyourbestfit-ar.com/models/CondomRigFixed16.glb';
	var androidLink = '?link=' + encodeURIComponent(window.location.href) + '&title=';

	var fullLinkAndroid = androidIntentPrefix + encodeURIComponent(androidModelLink) + androidLink + androidIntentSuffix;
	// see link in HTML
	//var iOSModelLink = 'https://findyourbestfit-ar.com/models/CondomRigFixed16.usdz';
    
	
	// function to register clicks
	function registerButtonClick(action) {
		console.log("GA_Click:" + action);

		gtag("event", "BC_" + action, {
			version: "Durex",
			method: action,
		});
	}

	const LaunchAR = () => {
		setModal(!modal);
		setModalMode("loading");

		registerButtonClick("launchAR");




		window.setTimeout(function () {
			if (isAndroid) {
				//console.log(fullLinkAndroid);
				saq('rt','QRAXmd6YxQvDTntyTdxKQC');
				window.open(fullLinkAndroid, '_blank');
				setModal(false);
			} else if (isIOS) {
				//window.open(iOSModelLink, '_blank');
				saq('rt','QRAXmd6YxQvDTntyTdxKQC')
				var fakeButton = document.getElementById('iosLauncher');
				fakeButton.click();
				setModal(false);
			}
			// If you need the redirect, or use window.open("https://findyourbestfit-ar.com/", "_self");
			// location.href = "https://findyourbestfit-ar.com/";
		}, 4000);
	};

	const LaunchCookies = () => {
		openModal(true);
		registerButtonClick("cookies");
	};

	const LaunchGoBack = () => {
		openModal(false);
		registerButtonClick("back");
	};

	useEffect(() => {
		const id = setInterval(() => setIndex((oldCount) => oldCount + 1), 4000);

		return () => {
			clearInterval(id);
		};
	}, []);

	useEffect(() => {
		if (index > 2) {
			setIndex(0);
		}
	}, [index]);

	return (
		<HeroBase
			className="d-flex flex-column justify-content-between"
			index={index}
		>
			<Header />
			<div className="top-desktop d-none d-lg-flex align-items-center">
				<DesktopQRCode src={QRCode} alt="QR Code" className="w-100 p-0 p-md-3 d-none d-lg-block" />
			</div>
			<div className="top d-flex d-lg-none position-relative overflow-hidden">
				<div className="product-1" />
				<div className="product-2" />
				<div className="product-3" />
				{/*
				<BgImage src={ThinAndLightBG} alt="bg" className={`position-absolute ${index === 0 && 'product-1'}`} />
				<BgImage src={SmoothAndSoftBG} alt="bg" className={`position-absolute ${index === 1 && 'product-2'}`} />
				<BgImage src={ThinAndSensitiveBG} alt="bg" className={`position-absolute ${index === 2 && 'product-3'}`} />
				*/}				
				<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 text-center" style={{ zIndex: "4" }}>
					{index === 0 && (
						<Product
							src={RegularSizeProduct}
							alt="Invisible Ultimate Thin"
							className="w-100 p-0 d-xxl-none"
						/>
					)}
					{index === 1 && <Product src={LargeSizeProduct} alt="RealFeel®" className="w-100 p-0 d-xxl-none" />}
					{index === 2 && <Product src={XLSizeProduct} alt="SensiThin®" className="w-100 p-0 d-xxl-none" />}
				</div>
			</div>
			<div className="middle d-flex justify-content-center">
				<DurexLogo src={DurexWhite} alt="Durex Logo" />
			</div>
			<div className="bottom">
				<div className="d-flex justify-content-center row container m-auto">
					<div className="col-12 text-center mt-lg-5">
						<MobileSpacer />
						<Paragraph weight="black" size="22px" className="mb-0 pt-5 d-lg-none" uppercase>
							One Size Doesn't Fit All
						</Paragraph>
						<Paragraph weight="black" size="36px" className="mb-0 pt-5 d-none d-lg-block" uppercase>
							One Size Doesn't Fit All
						</Paragraph>
						<Paragraph weight="thin" size="14px" className="mb-3 mb-lg-4 d-lg-none">
							The rings on the box tell you the size of the condom
						</Paragraph>
						<Paragraph weight="thin" size="28px" className="mb-3 mb-lg-4 d-none d-lg-block">
							Scan the QR code to launch in AR
						</Paragraph>
						<MobileSpacer />
					</div>
					<div className="d-flex sizeContainer justify-content-center align-items-center flex-column p-0">
						<div className="d-flex justify-content-center">
							<div className="text-center">
								<GirthIndicator selectedRing="4" />
								<Paragraph weight="black" className="mt-2 mb-0 text-center">
									REGULAR
								</Paragraph>
							</div>
							<div className="text-center">
								<GirthIndicator selectedRing="3" />
								<Paragraph weight="black" className="mt-2 mb-0 text-center">
									LARGE
								</Paragraph>
							</div>
							<div className="text-center">
								<GirthIndicator selectedRing="2" />
								<Paragraph weight="black" className="mt-2 mb-0 text-center">
									XL
								</Paragraph>
							</div>
						</div>
						
						<div>
							<ArrowIndicator index={index} src={SelectionArrow} alt="Selected" className="d-lg-none" />
						</div>

					</div>

					<div className="col-12 d-flex justify-content-end align-items-center pt-md-4 mt-2 flex-column pt-3 d-none d-lg-block">
						<Paragraph weight="thin" size="17px" className="mt-2 mb-0 text-center">
							The rings on the box tell you the size of the condom.
						</Paragraph>
						<Paragraph weight="thin" size="11px" className="mt-4 mb-0 text-center">
							Ensure this product is right for you. Always read and follow the label.
						</Paragraph>
					</div>

					
					<div className="col-12 d-flex justify-content-end align-items-center pt-md-4 mt-2 flex-column pt-3 d-lg-none">
						

						<BorderButton onClick={() => LaunchAR()}>Launch AR</BorderButton>
						<a rel="ar" id="iosLauncher" href="https://findyourbestfit-ar.com/models/CondomRigFixed16.usdz"><IosLaunchImage src={IosLaunch}/></a>

						<Paragraph weight="thin" size="11px" className="mt-3 mb-0 text-center">
							Ensure this product is right for you. Always read and follow the label.
						</Paragraph>
						<InvisibleButton onClick={() => LaunchCookies()} className="mt-1">
							<Paragraph weight="thin"><u>cookies</u></Paragraph>
						</InvisibleButton>
						
					</div>
				</div>
			</div>
			<StyledModal isOpen={modal} toggle={openModal}>
				{modalMode === "cookies" && (
					<ModalBody>
						<div>
							<Paragraph weight="black" size="20px">
								Cookie Policy
							</Paragraph>
							<Paragraph weight="black" size="20px">
								Strictly Necessary
							</Paragraph>
							<Paragraph size="16px">
								These Cookies are necessary for the website to function and cannot be switched off in
								our systems. They are usually only set in response to actions made by you which amount
								to a request for services, such as setting your privacy preferences, logging in and
								filling in forms. You can set your browser to block or alert you about these cookies,
								but some parts of the sits will not work. These cookies do not store any personally
								identifiable information.
							</Paragraph>
							<Paragraph weight="black" size="20px">
								Analytics Cookies
							</Paragraph>
							<Paragraph size="16px">
								These cookies allow us to count visits and traffic sources, so we can measure and
								improve the performance of our site. They help us know which pages are the most and
								least popular and see how visitors move around the site. All information these cookies
								collect is aggregated and therefore anonymous. If you do not allow these cookies, we
								will not know when you have visited our site.
							</Paragraph>
						</div>
						<div className="pb-5 mb-5 text-center">
							<PrimaryButton onClick={() => LaunchGoBack()}>Go Back</PrimaryButton>
						</div>
					</ModalBody>
				)}
				{modalMode === "loading" && (
					<ModalBody className="centered">
						<div className="d-flex flex-column col-10 col-md-5 col-xl-3 m-auto">
							<Paragraph size="7vw" weight="black" className="mb-1">
								#FOUNDMYBESTFIT
							</Paragraph>
							<Paragraph size="4.5vw" weight="thin" className="pb-5">
								Don't forget to share and tag a picture for a chance to win Game Night boxes.
							</Paragraph>
							<LoadingBar />
						</div>
					</ModalBody>
				)}
			</StyledModal>
		</HeroBase>
	);
};

export default Hero;
