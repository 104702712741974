import React from "react";
import styled from 'styled-components';
import ShareIcon from '../images/share-button.svg';
import { InvisibleButton } from "./buttons";

const NativeShare = (Title, URL, Description) => {

	// function to register clicks
	function registerButtonClick(action) {
		console.log("GA_Click:" + action);

		gtag("event", "BC_" + action, {
			version: "Durex",
			method: action,
		});
	}

	registerButtonClick("share");

	// We check if the browser supports the Native Share
	if (typeof navigator.share === "undefined" || !navigator.share) {
		alert("Your browser does not support Native Share, please use the share option of your browser");
	} else if (window.location.protocol !== "https:") {
		alert("Native Share supported only on Https:// protocol");
	} else {
		// If Android share is supported and the received values are undefined assign the corresponding values
		if (typeof URL === "undefined") {
			// URL = window.location.href;
			URL = "https://findyourbestfit-ar.com/";
		}
		if (typeof Title === "undefined") {
			Title = document.title;
		}
		if (typeof Description === "undefined") {
			Description =
				"One size doesn't fit all, Click here to launch AR experience";
		}

		var TitleConst = Title;
		var URLConst = URL;
		var DescriptionConst = Description;

		// Open the Android Share fed with the new values and a link
		try {
			navigator.share({ title: TitleConst, text: DescriptionConst, url: URLConst });
		} catch (error) {
			console.log("Error sharing: " + error);

			return;
		}
	}
};

const Icon = styled.img`
	max-width: 28px;
	min-width: 28px;
`;

const Header = () => (
	<div className="position-absolute p-2">
		<div className="d-flex justify-content-between align-items-start">
			<InvisibleButton onClick={() => NativeShare()} style={{ zIndex: "6" }}>
				<Icon src={ShareIcon} alt="Share" className="d-lg-none" />
			</InvisibleButton>
		</div>
	</div>
);

export default Header;
